import React, { Fragment } from 'react';
import ShowProductOrderStatusProperties from "./ShowProductOrderStatusProperties";
import { Box, Card, SvgIconTypeMap } from '@mui/material';
import styled from "@mui/material/styles/styled";
import FlexBetween from '../../../components/flex-box/flex-between';
import Avatar from "@mui/material/Avatar";
import ProductOrderStatus from '../../../model/productOrder/ProductOrderStatus';
import Created from '../../../components/icons/order-status/Created';
import Processing from '../../../components/icons/order-status/Procesing';
import PackageReceived from '../../../components/icons/order-status/PackageReceived';
import { Done, KeyboardArrowDown } from '@material-ui/icons';
import { Paragraph } from '../../../components/flex-box/Typography';
import { getUserOrderStatusIcon } from '../../../util/Icons';
import { OverridableComponent } from '@mui/material/OverridableComponent';
// STYLED COMPONENTS
const StyledFlexbox = styled(FlexBetween)(({ theme }) => ({
    flexWrap: "wrap",
    margin: "2rem",
    [theme.breakpoints.down("sm")]: { flexDirection: "column" },
    "& .line": {
      height: 4,
      minWidth: 50,
      flex: "1 1 0",
      [theme.breakpoints.down("sm")]: { flex: "unset", height: 50, minWidth: 4 },
    },
    "@media (max-width: 599px)": {
        marginLeft: '-50px'
    }
  }));
  const StyledStatusInfo = styled(Box)(({ theme }) => ({
    top: 70,
    left: 0,
    [theme.breakpoints.down("sm")]: { top: 8, left: 80 },
    position: "absolute",
  }));
  const StyledCheck = styled(Avatar)(({ theme }) => ({
    top: -5,
    right: 0,
    width: 25,
    height: 25,
    margin: 0,
    backgroundColor: "white",
    position: "absolute",
    border: "4px solid",
  }));
  const StyledCurrent = styled(Box)(({ theme }) => ({
    top: -20,
    right: 19,
    width: 25,
    height: 25,
    [theme.breakpoints.down("sm")]: { top: 20, left: -25, rotate: "270deg" },
    position: "absolute",
  }));
  
const ShowProductOrderStatus: React.FC<ShowProductOrderStatusProperties> = ({
                                                          className = '',
                                                          orderStatus,
                                                          statusHistory
                                                      }) => {
    const ORDER_STATUS_LIST = [ProductOrderStatus.CREATED, ProductOrderStatus.PROCESSING, ProductOrderStatus.DELIVERED];
    const ORDER_STATUS_LIST_LABELS = ['CREADA', 'PROCESANDO', 'ENTREGADA'];
    const STEP_ICONS = [Created, Processing, PackageReceived];
    
    const currentPosition = ORDER_STATUS_LIST.indexOf(orderStatus);

    const formatDate = (timestamp?: number) => {
        if (!timestamp) return '';
        const date = new Date(timestamp);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const min = date.getMinutes().toString().padStart(2, '0');
        return `${day}-${month}-${year} ${hour}:${min}`;
    };
    
    const X = (status: ProductOrderStatus)=> {
        const Icon = getUserOrderStatusIcon(status) as (OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string;})
        return <Icon fontSize='large' />
    }

    return (
        <Card sx={{ p: "2rem 1.5rem"}}>
            <StyledFlexbox>
                {ORDER_STATUS_LIST.map((status,ind) =>(
                    <Fragment key={ind}>
                        <Box position="relative">
                        <Avatar
                            alt="shipping"
                            sx={{
                            width: 64,
                            height: 64,
                            color: ind <= currentPosition ? "white" : "primary.dark",
                            bgcolor: ind <= currentPosition ? "primary.dark" : "white",
                            border: "4px solid",
                            borderColor: "grey.300",
                            }}
                        >
                                {X(ORDER_STATUS_LIST[ind])}
                            </Avatar>
                            {ind < currentPosition || currentPosition===2 ? (
                                <StyledCheck sx={{ borderColor: "primary.dark", color:"primary.dark" }}>
                                    <Done/>
                                </StyledCheck>
                            ) : null}
                            {ind === currentPosition && currentPosition!==2 ? (
                                <StyledCurrent sx={{ color:"primary.dark" }}>
                                    <KeyboardArrowDown  />
                                </StyledCurrent>
                            ) : null}
                            <StyledStatusInfo>
                                <Box justifyContent={{ xs: "center", sm: "flex-end" }}>
                                    <Paragraph
                                        ellipsis={true}
                                        textAlign="left"
                                        color={"primary.dark"}
                                        fontWeight={700}
                                    >
                                        {ORDER_STATUS_LIST_LABELS[ind]}
                                        <div>
                                            <small>
                                                {formatDate(statusHistory.find(t => t.status === status)?.date)}
                                            </small>
                                        </div>
                                    </Paragraph>
                                </Box>
                            </StyledStatusInfo>
                        </Box>
                        {ind < STEP_ICONS.length - 1 ? (
                            <Box
                                className="line"
                                sx={{
                                    bgcolor: ind < currentPosition ? "primary.dark" : "grey.300",
                                }
                            }
                            />
                            ) : null}
                    </Fragment>
                ))}
            </StyledFlexbox>
        </Card>
    )
}

export default ShowProductOrderStatus;