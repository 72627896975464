import createSvgIcon from "@mui/material/utils/createSvgIcon";

const PackageReceived = createSvgIcon(
<svg width="24" height="24" viewBox="-3 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.1594 10.5728H5.11085C3.98173 10.5728 3.06641 11.4881 3.06641 12.6172V29.2187C3.06641 30.3478 3.98174 31.2631 5.11085 31.2631H21.3294C22.4586 31.2631 23.3739 30.3478 23.3739 29.2187V16.8167M9.47232 10.8378V17.5972L13.0802 15.8742L16.6293 17.5972V11.0734" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<path d="M10.0518 24.1505H16.6962" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
<circle cx="25.0031" cy="9.03215" r="6.08941" stroke="currentColor" stroke-width="1.19259"/>
<path d="M22 7.89071L24.0444 10.2759L28.4817 6" stroke="currentColor" stroke-width="1.19259" stroke-linecap="round"/>
</svg>,
  "PackageReceived",
);

export default PackageReceived;