import React, { useEffect } from 'react';
import {Observer} from "mobx-react-lite";
import { useParams } from 'react-router-dom';
import ShowProductOrderStatus from '../../shared_components/Info/ShowProductOrderStatus/ShowProductOrderStatus';
import OrderTrackingStore from './OrderTrackingStore';
import { useCommonContext } from '../../contexts/CommonContext';
import { Box, Card, Grid } from '@mui/material';
import FlexBox from '../../components/flex-box/flex-box';
import { H2, H4, Span } from '../../components/flex-box/Typography';
import { ShoppingBag } from '@mui/icons-material';
const OrderTracking: React.FC = () => {

    const { id } = useParams();
    
    const commonStore = useCommonContext();
    const store = new OrderTrackingStore(commonStore, id);

    useEffect(() => {
        store.getProductOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Observer>{() => (
            <Box p={4}>
                <FlexBox alignItems="center" gap={1.5} mb={3}>
                    <ShoppingBag sx={{color:"primary.dark"}} />
                    <H2 ellipsis>Detalles de la Orden</H2>
                </FlexBox>
                {store.productOrder && 
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                            <ShowProductOrderStatus orderStatus={store.productOrder.status} statusHistory={store.productOrder.statusHistory}/>
                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                            <Card sx={{ p: 3 }}>
                                <FlexBox flexDirection={"column"} gap={2}>
                                    <FlexBox flexDirection={"column"}>
                                        <FlexBox alignItems={"baseline"} gap={1}>
                                            <Span color="grey.600">Remitente:</Span>{" "}
                                            <H4>{store.productOrder.clientName}</H4>
                                        </FlexBox>
                                        <FlexBox alignItems={"baseline"} gap={1}>
                                            <Span color="grey.600">Destinatario:</Span> 
                                            <H4>{store.productOrder.contactName}</H4>
                                        </FlexBox>
                                        <FlexBox alignItems={"baseline"} gap={1}>
                                            <Span color="grey.600">Dirección:</Span> 
                                            <H4>{store.productOrder.address}</H4>
                                        </FlexBox>
                                    </FlexBox>
                                </FlexBox>
                            </Card>
                        </Grid>
                    </Grid>
                }
            </Box>
        )}</Observer>
    )
}
export default OrderTracking;
