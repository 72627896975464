import createSvgIcon from "@mui/material/utils/createSvgIcon";

const Created = createSvgIcon(
<svg width="24" height="24" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M50.2833 40.9407C59.3796 40.9407 66.7537 33.5667 66.7537 24.4703C66.7537 15.374 59.3796 8 50.2833 8C41.187 8 33.813 15.374 33.813 24.4703C33.813 33.5667 41.187 40.9407 50.2833 40.9407Z" stroke="currentColor" stroke-width="3.5" stroke-miterlimit="10"/>
<path d="M42.7915 65.0575L57.4678 80.4195C58.2788 81.2684 59.6431 81.2394 60.4174 80.3569L90.1103 46.5117" stroke="currentColor" stroke-width="3.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M82.1972 70.5V76.9999C82.1972 80.3136 79.5109 82.9999 76.1972 82.9999H70.5" stroke="currentColor" stroke-width="3.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M73.2434 55.8915C73.2434 55.8915 46.1289 37.6843 19.8213 61.7537C18.6414 62.8333 18 64.3895 18 65.9888V76.9998C18 80.3135 20.6863 82.9997 24 82.9997H46.5816" stroke="currentColor" stroke-width="3.5" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>,
  "Created",
);

export default Created;