import { action, makeObservable, observable, runInAction } from "mobx";
import { ProductOrderService } from "../../service/productOrder/ProductOrderService";
import CommonStore from "../../stores/CommonStore";
import ProductOrderTrackerResponse from "../../model/productOrder/ProductOrderTrackerResponse";

class OrderTrackingStore {
    public commonStore: CommonStore;

    public productOrderId: string;
    public productOrder?: ProductOrderTrackerResponse;

    constructor(
        commonStore: CommonStore,
        productOrderId: string
    ) {
        this.commonStore = commonStore;
        this.productOrderId = productOrderId;
        makeObservable(this, {
            productOrder: observable,
        });
    }

    @action
    public async getProductOrder(): Promise<void> {
        // this.commonStore.setLoading();
        const response = await ProductOrderService.findProductOrder(this.productOrderId);
        if (response.success) {
            runInAction(() => {
                this.productOrder = response.data;
                console.log(33, this.productOrder)
            })
        }
        // this.commonStore.removeLoading();
    }
}

export default OrderTrackingStore;