import {BrowserRouter, Route, Routes} from "react-router-dom";
import Providers from "./Providers";
import OrderTracking from './pages/OrderTracking/OrderTracking';


function App() {
    return (
        <Providers>
            <BrowserRouter>
                <Routes>
                    <Route path={'/order-tracking/:id'}>
                        <OrderTracking/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Providers>

    );
}

export default App;
