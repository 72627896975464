import { components } from "./components";
import { primary, themeColors } from "./theme-colors";
import { typography } from "./typography";

const THEMES = {
  DEFAULT: "DEFAULT",
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};


const themesOptionList = {
  [THEMES.DEFAULT]: {
    typography,
    breakpoints,
    components,
    palette: { primary: { ...primary, light: primary.main }, ...themeColors },
  },
};

const themeOptions = () => {
  let themeOption;

  const updateTheme = (themeName: string) => {
    themeOption = themesOptionList[themeName];
  };

  updateTheme(THEMES.DEFAULT);

  return themeOption;
};

export default themeOptions;
