import { Components } from "@mui/material/styles/components";
import { typography } from "./typography";

// ========================================================
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    dark: true;
    paste: true;
    marron: true;
  }

  interface ButtonPropsSizeOverrides {
    normal: true;
  }
}
// =========================================================

export const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      "*": {
        margin: 0,
        padding: 0,
        boxSizing: "border-box",
        scrollBehavior: "smooth",
      },
      html: {
        scrollBehavior: "smooth",
      },
      p: {
        lineHeight: 1.75,
      },
      button: {
        fontSize: 14,
        fontFamily: typography.fontFamily,
      },
      ".MuiRating-sizeSmall": {
        fontSize: "20px",
      },
      a: {
        color: "inherit",
        textDecoration: "none",
      },
      ul: {
        margin: 0,
        padding: 0,
        listStyle: "none",
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: { zIndex: 0 },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: { borderRadius: 8 },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: { borderRadius: "8px" },
    },
  },
  MuiPagination: {
    defaultProps: {
      variant: "outlined",
      color: "primary",
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: { paddingTop: 8, paddingBottom: 8 },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        "& .secondary": { opacity: 0.4 },
      },
    },
  },
};
