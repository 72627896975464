import Request from "../../model/Request";
import Response from "../../model/Response";
import {RestClient} from "../../util/RestClient";
import {Config} from "../../util/Config";
import ProductOrderTrackerResponse from "../../model/productOrder/ProductOrderTrackerResponse";

export module ProductOrderService {

    export async function findProductOrder(id: string): Promise<Response<ProductOrderTrackerResponse>> {
        const url = Config.get("GET_PRODUCT_ORDER_TRACKER_URL", {id});
        const request = new Request(url)
        const response = await RestClient.get(request);
        if (response.success) {
            const result = new ProductOrderTrackerResponse(
                response.data.id,
                response.data.status,
                response.data.clientName,
                response.data.contactName ?? '',
                response.data.address ?? '',
                response.data.statusHistory
            )
            return new Response<ProductOrderTrackerResponse>(true, result, response.error);
        }
        return new Response<ProductOrderTrackerResponse>(true, undefined, response.error, response.status);
    }

}