import ProductOrderStatus from "./ProductOrderStatus";
import ProductOrderTrackerStatusHistoryResponse from "./ProductOrderTrackerStatusHistoryResponse";

class ProductOrderTrackerResponse {
    public id: string;
    public status: ProductOrderStatus;
    public clientName: string;
    public contactName?: string;
    public address?: string;
    public statusHistory: ProductOrderTrackerStatusHistoryResponse[];

    constructor(
        id: string,
        status: ProductOrderStatus,
        clientName: string,
        contactName: string,
        address: string,
        statusHistory: ProductOrderTrackerStatusHistoryResponse[],
    ) {
        this.id = id;
        this.status = status;
        this.clientName = clientName;
        this.contactName = contactName;
        this.address = address;
        this.statusHistory = statusHistory;
    }

}

export default ProductOrderTrackerResponse;