import React from 'react';
import MuiThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import {I18nContextProvider} from "./contexts/I18nContext";
import I18nStore from "./stores/I18nStore";
import Language from "./i18n/Language";
import {BreakpointProvider} from 'react-socks';
import {ConfirmProvider} from "material-ui-confirm";
import { ToastProvider } from 'react-toast-notifications';
import createTheme, { ThemeOptions } from "@mui/material/styles/createTheme";
import themeOptions from './theme/theme-options';
import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes";
const i18NStore = new I18nStore(Language.ES);


const Providers: React.FC = ({
                                 children
                             }
) => {
    const themeOption= themeOptions();
    let theme = createTheme(themeOption as unknown as ThemeOptions);
    theme = responsiveFontSizes(theme);

    // theme shadows
    theme.shadows[1] = "0px 1px 3px rgba(3, 0, 71, 0.09)";
    theme.shadows[2] = "0px 4px 16px rgba(43, 52, 69, 0.1)";
    theme.shadows[3] = "0px 8px 45px rgba(3, 0, 71, 0.09)";
    theme.shadows[4] = "0px 0px 28px rgba(3, 0, 71, 0.01)";
    return (
        <I18nContextProvider value={i18NStore}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <ConfirmProvider>
                    <BreakpointProvider>
                        <ToastProvider>
                            {children}
                        </ToastProvider>
                    </BreakpointProvider>
                </ConfirmProvider>
            </MuiThemeProvider>
        </I18nContextProvider>

    );
}

export default Providers;
