import Created from "../components/icons/order-status/Created";
import PackageReceived from "../components/icons/order-status/PackageReceived";
import Processing from "../components/icons/order-status/Procesing";
import ProductOrderStatus from "../model/productOrder/ProductOrderStatus";

function getUserOrderStatusIcon (userOrderStatus: ProductOrderStatus){
    switch(userOrderStatus){
      case ProductOrderStatus.CREATED: return Created;
      case ProductOrderStatus.PROCESSING: return Processing;
      case ProductOrderStatus.DELIVERED: return PackageReceived;
      default: return undefined
    }
}

export {getUserOrderStatusIcon};